import { translateMovementCategory } from "../../../utils/translate";
import { Icon } from '@iconify/react';


function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

const getColorStockCategoryType = (value?: string | null) => {
    let data = "";

    switch (value) {
        case "REFUNDED":
            return "bg-violet-300 text-violet-800"; // Refunded: Un color rojo, asociado con reembolsos o pérdidas.

        case "TRASLATE":
            return "bg-orange-200 text-orange-800"; // Translate: Azul índigo, asociado al movimiento y cambio.

        case "SALE":
            return "bg-sky-200 text-sky-800"; // Sale: Verde, asociado a ganancias o ventas.

        case "BUY":
            return "bg-indigo-200 text-teal-800"; // Buy: Un color azul verdoso, asociado con compras o inversiones.

        case "ADJUST":
            return "bg-fuchsia-200 text-fuchsia-800"; // Adjust: Amarillo, asociado a cambios o ajustes.

        case "PROCESSED":
            return "bg-amber-200 text-amber-800"; // Processed: Púrpura, asociado con procesos o transformación.

        case "DISPATCH":
            return "bg-lime-200 text-lime-800"; // Dispatch: Azul, asociado con envíos o movimiento.

        case "CONTAINER":
            return "bg-stone-200 text-stone-800"; // Container: Gris, asociado con almacenamiento o contenedores.

        case "EXTRACTION":
            return "bg-red-200 text-red-800"; // Extraction: Naranja, asociado con extracción o remoción.

        case "OUT":
            return "bg-red-200 text-red-800"; // Extraction: Naranja, asociado con extracción o remoción.

        case "FAST_ENTRY":
            return "bg-teal-200 text-teal-800"; // Fast Entry: Verde esmeralda, asociado con entradas rápidas.

        case "WASTE":
            return "bg-rose-300 text-rose-800"; // Waste: Marrón, asociado con desechos.

        case "TRANSFORMATION":
            return "bg-yellow-300 text-yellow-800"; // Transformation: Cian, asociado con cambios o transformaciones.

        default:
            data = "bg-blue-200 text-blue-800"; // Default: Fucsia, para representar categorías no especificadas.
    }

    return data;
};

const getCategoryIcon = (category?: string | null) => {

    switch (category) {
        case "REFUNDED":
            return "iconoir:receive-dollars"; // Refunded: Un color rojo, asociado con reembolsos o pérdidas.

        case "TRASLATE":
            return "fa6-solid:truck-ramp-box"; // Translate: Azul índigo, asociado al movimiento y cambio.

        case "SALE":
            return "tabler:currency-dollar"; // Sale: Verde, asociado a ganancias o ventas.

        case "BUY":
            return "tabler:currency-dollar"; // Buy: Un color azul verdoso, asociado con compras o inversiones.

        case "ADJUST":
            return "icon-park-outline:setting-config"; // Adjust: Amarillo, asociado a cambios o ajustes.

        case "PROCESSED":
            return "mingcute:fire-line"; // Processed: Púrpura, asociado con procesos o transformación.

        case "DISPATCH":
            return "hugeicons:safe-delivery-01"; // Dispatch: Azul, asociado con envíos o movimiento.

        case "CONTAINER":
            return "eos-icons:container-outlined"; // Container: Gris, asociado con almacenamiento o contenedores.

        case "EXTRACTION":
            return "basil:logout-solid"; // Extraction: Naranja, asociado con extracción o remoción.

        case "OUT":
            return "basil:logout-solid"; // Extraction: Naranja, asociado con extracción o remoción.

        case "FAST_ENTRY":
            return "basil:login-solid"; // Fast Entry: Verde esmeralda, asociado con entradas rápidas.

        case "WASTE":
            return "mdi:minus-box-outline"; // Waste: Marrón, asociado con desechos.

        case "TRANSFORMATION":
            return "grommet-icons:update"; // Transformation: Cian, asociado con cambios o transformaciones.

        default:
            return "";
    }
};

interface StockCategoryInterface {
    category?: string | null
}

const StockCategoryTypeBadge = ({ category }: StockCategoryInterface) => {
    return (
        <div
            className={classNames(
                getColorStockCategoryType(category),
                "inline-flex items-center h-7 px-2.5 py-0.5 rounded-full text-sm  font-medium md:mt-2 lg:mt-0 gap-x-1"
            )}
        >
            <Icon icon={
                getCategoryIcon(category)
            } />
            {translateMovementCategory(category!)}
        </div>
    )
}

export default StockCategoryTypeBadge
