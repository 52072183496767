import React from 'react'
import Select from '../../../components/forms/Select'
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppSelector } from '../../../store/hooks';
import Button from '../../../components/misc/Button';

interface ConvertToOrderModalInterface {
    orderId: number,
    setConvertToOrderWithAreaModal: React.Dispatch<React.SetStateAction<boolean>>,
    TransformPreReceiptIntoReceipt: Function,
    isFetching: boolean
}
export const ConvertToOrderModal = ({
    orderId,
    setConvertToOrderWithAreaModal,
    TransformPreReceiptIntoReceipt,
    isFetching
}: ConvertToOrderModalInterface) => {


    //React Hook Form-----------------------------------------------------------------------
    const { handleSubmit, control, watch } = useForm();

    const onSubmit: SubmitHandler<Record<string, string | number>> = async (data) => {
        const areaId = watch("areaId")

        TransformPreReceiptIntoReceipt!(orderId, !!areaId ? {
            areaSalesId: areaId
        } : {}, () => setConvertToOrderWithAreaModal(false))
    };

    //Areas ------------------------------------
    const { areas } = useAppSelector((state) => state.nomenclator);

    const stockAreas =
        areas
            ?.filter((area) => area.type === "SALE")
            .map(({ id, name }) => {
                return { id, name };
            }) || [];

    return (
        <form>
            <h2 className="text-xl font-semibold mb-6">
                Convertir a factura
            </h2>
            <div className="flex flex-col gap-2 w-full">

                <div className="py-1 col-span-2">
                    <Select
                        name="areaId"
                        data={stockAreas}
                        label="Seleccione un área de venta para registrar la nueva orden *"
                        control={control}
                        rules={{ required: "Este campo es requerido" }}
                    />
                </div>

                <div className="w-full flex justify-end gap-3 mt-4">

                    <div>
                        <Button
                            color="slate-600"
                            type="button"
                            name="Aceptar"
                            action={handleSubmit(onSubmit)}
                            loading={isFetching}
                            disabled={isFetching}
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}
