import moment from "moment";
import { useAppSelector } from "../../../../store/hooks";
import { useState } from "react";
import { translateOrderState } from "../../../../utils/translate";
import { exportExcel, filtrarPropiedades, generatePdf } from "../../../../utils/helpers";
import MultipleActBtn, { BtnActions } from "../../../../components/misc/MultipleActBtn";
import { FaRegFilePdf } from "react-icons/fa";
import Modal from "../../../../components/misc/GenericModal";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "../../../../components/forms/Input";
import Button from "../../../../components/misc/Button";
import SaleByOrderReport from "../../../../reports/SaleByOrderReport";
import { BsFiletypeXlsx } from "react-icons/bs";
import { printPriceWithCommasAndPeriods } from "../../../../utils/functions";

interface TransferAndCash {
  amount: number,
  codeCurrency: string,
  paymentWay?: string
}

interface ObjectCoupon {
  code: string;
  amount: number;
  discountType: string;
}

interface ObjectTotalToPay {
  amount: number;
  codeCurrency: string;
}

interface ObjectTable {
  discount: number;
  businessId: number;
  origin: string;
  houseCosted: boolean;
  status: string;
  currenciesPayment: TransferAndCash[];
  coupons: ObjectCoupon[];
  totalToPay: ObjectTotalToPay[];
  createdAt: string;
  paidAt: string;
  operationNumber: number;
  name: string;
  salesBy: Record<string, string>;
  managedBy: Record<string, string>;
  observations: string;
  client: Record<string, string | null> | null;
  commission: number;
  totalCost: number;
  tipPrice: TransferAndCash;
  prices: { price: number, codeCurrency: string; }[];
}

const PdfAndExcelSalesByOrders = (props: any) => {

  const { salesbyOrders } = props.dataAccess;

  let resumen: Record<string, any> = salesbyOrders?.summarize;

  let totalVendido: string, totalIngresado: string, tips: string, consumoCasa: string, envios: string, descuentos: string, comisiones: string;

  const { titlesForExport } = useAppSelector((state) => state.nomenclator);

  if (resumen) {
    totalVendido = priceProcessor(resumen?.totalSales);
    totalIngresado = priceProcessor(resumen?.totalIncomes);
    tips = `${resumen?.totalTipsMainCurrency?.amount} ${resumen?.totalTipsMainCurrency?.codeCurrency}`;
    consumoCasa = priceProcessor(resumen?.totalHouseCosted);
    envios = priceProcessor(resumen?.totalShipping);
    descuentos = priceProcessor(resumen?.totalDiscounts);
    comisiones = priceProcessor(resumen?.totalCommissions);
  }

  const [exportModal, setExportModal] = useState(false);

  let data = salesbyOrders?.orders.map((elem: ObjectTable) => {
    let objectData: any = {
      'Fecha de creación': moment(elem?.createdAt).format("DD/MM/YYYY hh:mm A"),
      'Fecha de pago': moment(elem?.paidAt).format("DD/MM/YYYY hh:mm A"),
      'Punto de venta': elem.businessId,
      'Cliente': elem?.client === null ? '-' : `${elem?.client.firstName} ${elem?.client.lastName}`,
      'Origen': elem?.origin === 'pos' ? 'Puntos de venta' : (elem.origin === 'online' ? 'Tienda online' : ''),
      'Consumo casa': elem?.houseCosted ? 'Si' : 'No',
      'Estado': translateOrderState(elem.status),
      'Métodos de pago': elem?.currenciesPayment?.length > 0 ? sustituirValores(elem?.currenciesPayment.map(objeto => `${objeto.paymentWay}`)).join(', ') : '-',
      'Descuentos': elem?.discount,
      'Cupones': elem?.coupons?.length > 0 ? elem?.coupons.map(objeto => `${objeto.code}`).join(', ') : '-',
      'Numero de orden': elem?.operationNumber,
      'Nombre': elem?.name,
      'Contiene propina': elem?.tipPrice === null ? 'No' : `${elem?.tipPrice.amount} ${elem?.tipPrice.codeCurrency}`,
      'Vendido por': elem?.salesBy?.displayName,
      'Manejado por': elem?.managedBy?.displayName,
      'Observaciones': elem?.managedBy?.observations,
      'Comisiones': elem?.commission,
      'Costos': elem?.totalCost,
    };

    if (elem?.prices?.length > 0) {
      elem.prices.forEach((price) => {
        objectData[`Subtotal ${price.codeCurrency}`] =
          `${printPriceWithCommasAndPeriods(price.price)} ${price.codeCurrency}`;
      });
    }
    if (elem?.totalToPay?.length > 0) {
      elem.totalToPay.forEach((price) => {
        objectData[`Total ${price.codeCurrency}`] =
          `${printPriceWithCommasAndPeriods(price.amount)} ${price.codeCurrency}`;
      });
    }
    if (elem?.currenciesPayment?.length > 0) {
      elem.currenciesPayment.forEach((price) => {
        objectData[`Total ${price.codeCurrency}`] =
          `${printPriceWithCommasAndPeriods(price.amount)} ${price.codeCurrency}`;
      });
    }
    return objectData;
  })

  if (data && Array.isArray(data) && titlesForExport) data = filtrarPropiedades(data, titlesForExport);

  let dataForExcel: any
  let codeGeneratorValues: any

  if (data && Array.isArray(data) && titlesForExport) {
    dataForExcel = procesarPropiedades(data);
    codeGeneratorValues = codeGenerator(dataForExcel);
  }

  const exportAction = (name: string) => {
    const dataToExport: Record<string, string | number>[] = codeGeneratorValues ?? [];
    exportExcel(dataToExport, name);
  };

  const handleExport = () => {
    generatePdf(
      //@ts-ignore
      SaleByOrderReport({
        data, totalVendido, totalIngresado, tips, consumoCasa, envios, descuentos, comisiones
      })
      ,
      "Reporte de venta por ordenes"
    )
  }
  const actions: BtnActions[] = [
    {
      title: "Exportar pdf",
      icon: <FaRegFilePdf className="h-5 text-gray-500" />,
      action: handleExport
    },
    {
      title: "Exportar excel",
      icon: <BsFiletypeXlsx className="h-5 text-gray-500" />,
      action: () => setExportModal(true)
    },
  ];

  return (
    <div className={props.show ? '' : 'hidden'}>
      <div className=" mb-2">
        <MultipleActBtn items={actions} />
      </div>

      {exportModal && (
        <Modal state={exportModal} close={setExportModal}>
          <ExportModalContainer
            exportAction={exportAction}
            close={() => setExportModal(false)}
          />
        </Modal>
      )}

    </div>
  )
}

export default PdfAndExcelSalesByOrders


function sustituirValores(array: string[]) {
  return array.map(valor => {
    if (valor === "CASH") {
      return "Efectivo";
    } else if (valor === "TRANSFER") {
      return "Transferencia";
    } else {
      return valor; // Mantener el valor original si no coincide con "CASH" o "TRANSFER"
    }
  });
}

const ExportModalContainer = ({
  exportAction,
  close,
}: {
  exportAction: Function;
  close: Function;
}) => {
  const { control, handleSubmit } = useForm();
  const submit: SubmitHandler<Record<string, string>> = (data) => {
    exportAction(data.name);
    close();
  };
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Input
        name="name"
        control={control}
        label="Nombre del archivo .xlsx"
        rules={{ required: "Requerido *" }}
      />
      <div className="flex justify-end py-2">
        <Button color="slate-600" name="Aceptar" type="submit" />
      </div>
    </form>
  );
};

function priceProcessor(price: TransferAndCash[]) {
  if (!Array.isArray(price)) return '0';
  if (price.length === 0) return '0';
  let result = price.map(objeto => `${printPriceWithCommasAndPeriods(objeto.amount)} ${objeto.codeCurrency}`).join(', ');
  return result;
}

interface ObjetoConPropiedades {
  [key: string]: string | number; // Puedes ajustar el tipo según las propiedades específicas
}

export function procesarPropiedades(arrayDeObjetos: ObjetoConPropiedades[]): ObjetoConPropiedades[] {
  // Crear un nuevo array para almacenar los objetos modificados
  const nuevoArray: ObjetoConPropiedades[] = [];

  arrayDeObjetos.forEach(objeto => {
    // Clonar el objeto para no modificar el original
    const objetoModificado: ObjetoConPropiedades = { ...objeto };

    // Ordenar alfabéticamente las propiedades del objeto
    const propiedadesOrdenadas = Object.entries(objetoModificado)
      .sort(([a], [b]) => a.localeCompare(b));

    const objetoOrdenado: ObjetoConPropiedades = Object.fromEntries(propiedadesOrdenadas);

    // Buscar las propiedades Subtotal, Total y Total Pagado
    const propiedadesAsociadas = ['Subtotal', 'Total', 'Total Pagado'];

    // Crear un nuevo objeto con las propiedades ordenadas y las asociadas
    const nuevoObjeto: ObjetoConPropiedades = {};
    propiedadesOrdenadas.forEach(([clave, valor]) => {
      nuevoObjeto[clave] = valor;
      if (propiedadesAsociadas.includes(clave)) {
        // Si es una propiedad asociada, agregar la propiedad Code justo después
        nuevoObjeto[`${clave}-moneda`] = objetoOrdenado[`${clave}-moneda`];
      }
    });

    // Agregar el objeto modificado al nuevo array
    nuevoArray.push(nuevoObjeto);
  });

  // Devolver el nuevo array
  return nuevoArray;
}

export function codeGenerator(arrayDeObjetos: ObjetoConPropiedades[]): ObjetoConPropiedades[] {
  const nuevoArray: ObjetoConPropiedades[] = [];

  arrayDeObjetos.forEach(objeto => {
    const objetoModificado: ObjetoConPropiedades = { ...objeto };

    ['Subtotal', 'Total', 'Total Pagado'].forEach(prop => {
      if (objetoModificado.hasOwnProperty(prop)) {
        let valorOriginal = objetoModificado[prop] as string;

        valorOriginal = extraerNumeroYLetras(valorOriginal);
        const matches = valorOriginal.match(/([\d,]+)\s([A-Z]+)/);

        if (matches) {
          const cantidad = parseFloat(matches[1].replace(',', ''));
          const codigoMoneda = matches[2];

          objetoModificado[prop] = cantidad;
          objetoModificado[`${prop}-moneda`] = codigoMoneda;
        }
      }
    });

    nuevoArray.push(objetoModificado);
  });

  return nuevoArray;
}


function extraerNumeroYLetras(valor: string) {
  // Extraer solo dígitos y comas antes de convertir a número
  const soloDigitos = valor.match(/[\d,]+/g);
  // Unir los dígitos y reemplazar las comas antes de convertir a número
  const valorLimpio = soloDigitos ? soloDigitos.join('').replace(',', '.') : '';
  // Conservar las letras al final
  const letras = valor.replace(/[.\d, ]+/g, '').trim();
  // Convertir a número
  const numero = parseFloat(valorLimpio);
  return `${numero} ${letras}`;
}





