import React, { Dispatch, SetStateAction, useState } from 'react'
import Button from '../../../../../components/misc/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formatDateForReportsWithYearAndHour, isOpenDateCloser } from '../../../../../utils/helpers';
import { EconomicCycle } from '../../../../../interfaces/Interfaces';
import Modal from '../../../../../components/misc/GenericModal';
import CalendarEconomicCycle from '../../../../billing/analysis/analysisModals/CalendarEconomicCycle';
import Select from '../../../../../components/forms/Select';
import { useAppSelector } from '../../../../../store/hooks';
import useServerReports from '../../../../../api/useServerReports';
import { toast } from 'react-toastify';
import StockInventoryFromEcoCycles from './StockInventoryFromEcoCycles';


interface MovementInventoryReportInterface {
    setShowModalInventoryMovement: Dispatch<SetStateAction<boolean>>
}


export const MovementInventoryReport = (
    {
        setShowModalInventoryMovement
    }: MovementInventoryReportInterface
) => {

    const { areas } = useAppSelector((state) => state.nomenclator);

    const [filterByDateFromModal, setFilterByDateFromModal] = useState(false);
    const [econCicleFromSelected, setEconCicleFromSelected] = useState<EconomicCycle>();

    const [filterByDateToModal, setFilterByDateToModal] = useState(false);
    const [econCicleToSelected, setEconCicleToSelected] = useState<EconomicCycle>();

    const [showModalStockInventoryFromEcoCycles, setShowModalStockInventoryFromEcoCycles] =
        useState<{
            state: boolean, data: {
                areaId: number,
                economicCycleFromId: number,
                economicCycleToId: number
            }
        } | null>(null);

    //React Hook Form-----------------------------------------------------------------------
    const { handleSubmit, control, setValue } = useForm();

    const onSubmit: SubmitHandler<Record<string, string | number>> = async (data) => {

        const validateDatesSelected = isOpenDateCloser(econCicleToSelected?.openDate!, econCicleFromSelected?.closedDate!)

        const validateEcoCyclesSelected = !!data.economicCycleFromId && !!data.economicCycleToId && validateDatesSelected

        if (!!validateEcoCyclesSelected) {
            const dataToSend: {
                areaId: number,
                economicCycleFromId: number,
                economicCycleToId: number
            } = {
                areaId: +data.areaId!,
                economicCycleFromId: +data.economicCycleFromId!,
                economicCycleToId: +data.economicCycleToId!
            }

            setShowModalStockInventoryFromEcoCycles({
                state: true,
                data: dataToSend
            })
        } else if (validateDatesSelected === false) {
            toast.error("El ciclo económico de fin no puede ser una fecha antes que el de inicio")
        } else {
            toast.error("Debe seleccionar el ciclo económico de inicio y fin")
        }

    };

    const stockAreas =
        areas
            ?.filter((area) => area.type === 'STOCK')
            .map(({ id, name }) => {
                return { id, name };
            }) || [];



    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className='grid grid-cols-1 gap-2 items-start justify-start'>
                <h2 className=" text-xl font-semibold mb-6">
                    Movimiento de inventario histórico por rangos de ciclos económicos
                </h2>


                <h2 className=" text-start text-md font-semibold ">
                    Seleccionar ciclo económico de
                </h2>

                {/* Ecocycle From */}
                <div className="flex gap-2  items-center justify-start w-full">
                    <span className="w-full">
                        <Button
                            color="gray-200"
                            textColor="slate-900"
                            type="button"
                            name="Inicio"
                            outline
                            full
                            action={() => {
                                setFilterByDateFromModal(true);
                            }}
                        />
                    </span>
                    {econCicleFromSelected?.openDate && econCicleFromSelected?.closedDate && (
                        <span className="w-full">
                            {formatDateForReportsWithYearAndHour(
                                econCicleFromSelected?.openDate
                            )}{" "}
                            {!!econCicleFromSelected?.openDate && !!econCicleFromSelected?.closedDate
                                ? "-"
                                : ""}{" "}
                            {formatDateForReportsWithYearAndHour(
                                econCicleFromSelected?.closedDate
                            )}
                        </span>
                    )}
                </div>


                {/* Ecocycle To */}
                <div className="flex gap-2 items-center w-full">
                    <span className="w-full">
                        <Button
                            color="gray-200"
                            textColor="slate-900"
                            type="button"
                            name="Fin"
                            outline
                            full
                            action={() => {
                                setFilterByDateToModal(true);
                            }}
                        />
                    </span>
                    {econCicleToSelected?.openDate && econCicleToSelected?.closedDate && (
                        <span className="w-full">
                            {formatDateForReportsWithYearAndHour(
                                econCicleToSelected?.openDate
                            )}{" "}
                            {!!econCicleToSelected?.openDate && !!econCicleToSelected?.closedDate
                                ? "-"
                                : ""}{" "}
                            {formatDateForReportsWithYearAndHour(
                                econCicleToSelected?.closedDate
                            )}
                        </span>
                    )}
                </div>


                <Select
                    name='areaId'
                    data={stockAreas!}
                    label='Área *'
                    control={control}
                    rules={{ required: 'Este campo es requerido' }}
                />

                <div className=" w-full flex justify-end gap-3 mt-4">
                    <div>
                        <Button
                            color="slate-600"
                            textColor="slate-600"
                            type="submit"
                            name="Cancelar"
                            outline
                            action={() => {
                                setShowModalInventoryMovement(false);
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            color="slate-600"
                            type="submit"
                            name="Generar"

                        />
                    </div>
                </div>
            </form >


            {filterByDateFromModal && (
                <Modal
                    close={() => setFilterByDateFromModal(false)}
                    state={filterByDateFromModal}
                    size="m"
                >
                    <CalendarEconomicCycle
                        setShowDate={setFilterByDateFromModal}
                        setValue={setValue}
                        setEconCiclSelected={setEconCicleFromSelected}
                        value={"economicCycleFromId"}
                    />
                </Modal>
            )}

            {filterByDateToModal && (
                <Modal
                    close={() => setFilterByDateToModal(false)}
                    state={filterByDateToModal}
                    size="m"
                >
                    <CalendarEconomicCycle
                        setShowDate={setFilterByDateToModal}
                        setValue={setValue}
                        setEconCiclSelected={setEconCicleToSelected}
                        value={"economicCycleToId"}
                    />
                </Modal>
            )}


            {!!showModalStockInventoryFromEcoCycles?.state && (
                <Modal
                    state={!!showModalStockInventoryFromEcoCycles.state}
                    close={() => setShowModalStockInventoryFromEcoCycles(null)}
                    size="l"
                >
                    <StockInventoryFromEcoCycles data={showModalStockInventoryFromEcoCycles.data} />
                </Modal>
            )}
        </>
    )
}
