import React, { useContext, useState } from 'react'
import { TabsAttr } from '../../../components/misc/SideNav';
import TabNav from '../../../components/navigation/TabNav';
import Processed from './Processed';
import Labels from './Labels';
import ProcessedGeneral from './ProcessedGeneral';
import { DetailProductContext } from '../DetailProductContainer';

const ProcessedAndLabels = () => {

  const { product } = useContext(DetailProductContext);

  const [currentTab, setCurrentTab] = useState("proc");

  const tabs: TabsAttr[] = [
    {
      name: "Áreas de procesado",
      href: "proc",
      current: currentTab === "proc",
    },
    {
      name: "Etiquetas",
      href: "labels",
      current: currentTab === "labels",
    }
  ];

  if (product?.type === "MENU") {
    tabs.push(
      {
        name: "Generales",
        href: "general",
        current: currentTab === "general",
      },
    )
  }

  return (
    <div>
      <TabNav action={(tab: string) => setCurrentTab(tab)} tabs={tabs} />

      {currentTab === "proc" && <Processed />}
      {currentTab === "labels" && <Labels />}
      {currentTab === "general" && product?.type === "MENU" && <ProcessedGeneral />}
    </div>
  )
}

export default ProcessedAndLabels
