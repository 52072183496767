/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../store/hooks";
import { translateMeasure } from "../../../../../utils/translate";
import { SelectInterface } from "../../../../../interfaces/InterfacesLocal";
import Input from "../../../../../components/forms/Input";
import Select from "../../../../../components/forms/Select";
import SearchComponent from "../../../../../components/misc/SearchComponent";
import SpinnerLoading from "../../../../../components/misc/SpinnerLoading";
import EmptyList from "../../../../../components/misc/EmptyList";
import { RegisterContext } from "../../AllRegistersList";
import { EditContextBilling } from "../../registerDetailsTabs/RegisterDetailsTab";
import ScrollTypeFilter from "../../../../../components/misc/ScrollTypeFilter";
import useServerOrders from "../../../../../api/useServerOrders";
import { Price, ProductSale } from "../../../../../interfaces/Interfaces";
import {
  truncateValue,
  mathOperation,
  formatCurrency,
} from "../../../../../utils/helpers";
import { PlusIcon } from "@heroicons/react/24/outline";
import CurrencyAmountInput from "../../../../../components/forms/CurrencyAmountInput";
import ProductTypeBadge from "../../../../../components/misc/badges/ProductTypeBadge";
import CustomRadioV2, {
  CustomRadioData2,
} from "../../../../../components/forms/CustomRadioV2";

// NEW element modal
export const NewProductBillingModal3 = () => {
  const { clearErrors } = useForm();
  const [search] = useState<string | null>(null);
  const { areas: allAreas } = useAppSelector((state) => state.nomenclator);
  const { business } = useAppSelector((state) => state.init);
  const priceSystems = business?.priceSystems.find(
    (item) => item.isMain === true
  );

  const {
    watch,
    append,
    setValue,
    control,
    fields: selectedProductsList,
    update,
    orderById
  } = useContext(RegisterContext);
  const { getProductsByAreaSearch, saleProductsSearch, isLoading } =
    useServerOrders();
  const {
    defaultValues,
    editMode,
    addProductsToAddArray
  } = useContext(EditContextBilling);

  const type = watch!("registerType");
  const isPreBilling = type !== "BILLING";
  const initFilter = null;
  const [filter, setFilter] = useState<Record<
    string,
    string | number | boolean | null
  > | null>(initFilter);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      notStrickStock: type === "PRE-BILLING"
    }));
  }, [type]);

  //------------------------Config key --->
  const enable_to_sale_in_negative =
    business?.configurationsKey.find(
      (itm) => itm.key === "enable_to_sale_in_negative"
    )?.value === "true";
  //------------------------Config key --->

  // ----------------------- check is order online --->
  const originsOnline = ["marketplace", "online", "shop", "shopapk"];
  const isOrderOnline = originsOnline.includes(defaultValues?.origin!);

  //===> Area Data
  const areaSalesId = watch!("areaSalesId");
  const areaSalesSelected = allAreas?.find((area) => area?.id === areaSalesId);
  const stockAreaId = allAreas?.find(
    (area) => area?.id === areaSalesId
  )?.stockAreaId;

  const allowProductsMultiprice = allAreas.find(
    (area) => area.id === areaSalesId
  )?.allowProductsMultiprice;
  const allowManualPrice = allAreas?.find(
    (area) => area.id === areaSalesId
  )?.allowManualPrice;

  const currenciesSelector =
    business?.availableCurrencies.map((itm) => itm.code) ?? [];


  const validateQuantity = (
    quantityToBuy: number,
    stockAvailable: number,
    stockLimit: boolean
  ) => {
    if (quantityToBuy <= 0) {
      return "Seleccione una cantidad válida.";
    }
    if (
      !enable_to_sale_in_negative &&
      !isPreBilling &&
      stockLimit &&
      quantityToBuy > stockAvailable
    ) {
      return `Excediste la cantidad disponible ${stockAvailable}`;
    }
    return null;
  };
  //Data for list product ------------------------------------------------------------------
  const onSubmitAdd = async (data?: any) => {
    const supplyProductId = watch!("supplyProductId") || data?.supplyProductId;
    const quantityToBuy = watch!("quantityToBuy") || data?.quantityToBuy;
    const priceId = watch!("priceToSell") || data?.priceId;
    const variationId = watch!("variationId");
    const priceToSellManual = watch!("priceToSellManual");

    const checkProduct = saleProductsSearch.find(
      (item) => item.id === supplyProductId
    );

    const selectedProductQuantity =
      selectedProductsList?.find((item) => item?.product?.id === supplyProductId)
        ?.quantity ?? 0;

    if (checkProduct) {
      const stockArea = checkProduct?.stockAreaProducts.find(
        (item) => item?.areaId === areaSalesSelected?.stockAreaId
      );

      // Validación para productos de tipo COMBO
      if (checkProduct.type === "COMBO") {
        const error = validateQuantity(
          quantityToBuy,
          checkProduct.totalQuantity - selectedProductQuantity,
          checkProduct.stockLimit
        );
        if (error) return toast.warn(error);
      }

      // Validación para productos normales con stock
      if (stockArea) {
        const error = validateQuantity(
          quantityToBuy,
          stockArea?.quantity - selectedProductQuantity,
          checkProduct.stockLimit
        );
        if (error) return toast.warn(error);
      }
    }

    const priceManual = {
      price: priceToSellManual?.amount,
      codeCurrency: priceToSellManual?.codeCurrency,
    };

    if (supplyProductId && quantityToBuy && priceId) {
      const productSelected = saleProductsSearch!.find(
        (prod) => prod.id === supplyProductId
      ) as ProductSale;

      const priceSelect =
        typeof priceId === "string" && priceId !== "manual"
          ? JSON.parse(priceId)
          : priceManual;

      let productData = {
        product: productSelected,
        quantity: quantityToBuy,
        price: priceSelect,
        measure: translateMeasure(productSelected.measure),
        allowQuantity: 0, // Aquí iría la lógica específica de allowQuantity
        ...(variationId && { variationId, variationName: "Nombre de Variación" }),
      };

      // Si el producto ya existe en la lista, actualizamos la cantidad
      const productExistInArray = selectedProductsList?.find((field) => {
        const idField = field.product.id ?? field.product.productId;
        return field.variationId
          ? field.product.id === productData.product?.id &&
          field.variationId === variationId
          : idField === productData.product?.id;
      });

      if (productExistInArray) {
        const indexOfProduct = selectedProductsList?.findIndex(
          (item) => item?.id === productExistInArray.id
        );
        update!(indexOfProduct!, {
          quantity:
            Number(productExistInArray.quantity) + Number(productData.quantity),
          price: productData.price,
          product: productData.product,
          measure: productData.measure,
          allowQuantity: productData?.allowQuantity,
          ...(productData.variationId && { variationId: productData.variationId }),
          ...(productData.variationName && {
            variationName: productData?.variationName,
          }),
        });

        editMode &&
          defaultValues?.selledProducts?.map((deflProds) => {
            deflProds?.productId === productExistInArray?.product.productId &&
              addProductsToAddArray!({
                quantity: Number(productData.quantity),
                price: productData.price,
                product: productData.product,
                measure: productData.measure,
                allowQuantity: productData?.allowQuantity,
                ...(productData.variationId && {
                  variationId: productData.variationId,
                }),
              });
          });

        const productExistInOrden = orderById?.selledProducts.find((item) => {
          const productId =
            productExistInArray.product.id ||
            productExistInArray.product.productId;
          if (
            item.productId === productId &&
            item.priceUnitary.codeCurrency ===
            productExistInArray.price.codeCurrency &&
            item.priceUnitary.amount === productExistInArray.price.price
          ) {
            return item;
          }
        });
        // control state array for edit products in order
        if (editMode && productExistInArray) {
          //@ts-ignore
          setAddedProductsArray!((data: any) => {
            const update = data.map((item: any) => {
              if (item.product.id === supplyProductId) {
                let fieldAdd = item;
                const newQuantity =
                  Number(productExistInArray.quantity) +
                  Number(productData.quantity);

                item.quantity = productExistInOrden
                  ? newQuantity - productExistInOrden.quantity
                  : newQuantity;

                return fieldAdd;
              }
              return item;
            });
            return update;
          });
        }

      } else {
        // Si el producto no existe en la lista, lo agregamos
        append!(productData);

        !!editMode && addProductsToAddArray!(productData);
      }

      

      // Limpiar campos después de agregar
      setValue!("variationId", undefined);
      setValue!("supplyProductId", "");
      setValue!("quantityToBuy", "");
      setValue!("priceToSell", "");
      setValue!("priceToSellManual", null);

      toast.success("Producto agregado a la orden");
    } else {
      if (!quantityToBuy) toast.warn("Seleccione del producto una cantidad disponible");
      if (!priceId) toast.warn("Seleccione el precio del producto");
    }
  };
  const areaStockId = watch!("areaStockId");

  const memoizedFilter = useMemo(() => filter, [filter, areaSalesId, areaStockId]);

  useEffect(() => {
    if (areaSalesId || areaStockId) {
      const areaToSearch = areaSalesId ?? areaStockId;
      getProductsByAreaSearch!(areaToSearch, {
        ...memoizedFilter,
        areaStockSelect: isOrderOnline
      });
    }
  }, [areaSalesId, memoizedFilter]);

  const productFromStockList: CustomRadioData2[] = [];
  const productSelected = watch!("supplyProductId");

  const pushingData = (productFromStock: ProductSale) => {
    const quantity =
      productFromStock.stockAreaProducts.find(
        (item: any) => item.areaId === stockAreaId
      )?.quantity ?? 0;

    // Precios del producto
    const productOnSale = productFromStock?.onSale;
    const productOnSaleDiscount = productFromStock?.onSaleDiscountAmount || 0;
    const productOnSaleType = productFromStock?.onSaleType;
    let onSalePrice =
      productFromStock?.onSalePrice ?? { amount: 0, codeCurrency: "" } as Price;

    const productPricesSelect: any[] = [];
    productFromStock?.prices.forEach((price: any) => {
      if (productOnSaleType === "percent" && productOnSale && onSalePrice) {
        const discount = 1 - productOnSaleDiscount / 100;
        onSalePrice.amount = mathOperation(price?.price, discount, "multiplication", 2);
        onSalePrice.codeCurrency = price?.codeCurrency;
      }

      const idOnSale = JSON.stringify({
        price: onSalePrice?.amount,
        codeCurrency: onSalePrice?.codeCurrency,
      });

      // Si se permite multiprecio o si no se permite y es el sistema de precios principal
      if (allowProductsMultiprice || price.priceSystemId === priceSystems?.id) {
        const priceSelect = {
          id: productOnSale ? idOnSale : JSON.stringify({ ...price }),
          name: productOnSale ? (
            <span className="flex gap-x-2">
              {onSalePrice?.amount} {onSalePrice?.codeCurrency}
              <span className="line-through">
                {price?.price} {price?.codeCurrency}
              </span>
            </span>
          ) : (
            ` ${price?.price} ${price?.codeCurrency}`
          ),
        };
        productPricesSelect.push(priceSelect);
      }
    });

    // Permitir precio manual
    if (allowManualPrice) {
      productPricesSelect.push({ id: "manual", name: "Manual" });
    }

    // FIX: Cantidad disponible de variaciones
    const selectedProductQuantity =
      selectedProductsList?.find(
        (item) =>
          item?.product?.id === productFromStock?.id &&
          item?.variationId === watch!("variationId")
      )?.quantity ?? 0;

    const getStock = () => {
      if (productFromStock.type === "SERVICE") {
        return "";
      }

      if (productFromStock.type === "COMBO") {
        return productFromStock?.totalQuantity - selectedProductQuantity;
      }

      return quantity - selectedProductQuantity;
    };

    const stock = getStock();

    const priceDefault = JSON.parse(productPricesSelect[0].id) ?? "";

    const dataDefaultToAdd = {
      supplyProductId: productFromStock.id,
      quantityToBuy: watch!("quantityToBuy") || 1,
      // Todas las combinaciones del precio: manual, del select, o el primero por defecto
      priceId:
        watch!("priceToSell") ||
        watch!("priceToSellManual") ||
        productPricesSelect[0].id,
    };

    // Población de la lista de productos
    productFromStockList.push({
      value: productFromStock.id,
      img: productFromStock.images[0]?.src || require("../../../../../assets/image-default.jpg"),
      name: productFromStock.name,
      endElement: (
        <div className="w-[10px] -mx-10">
          <PlusIcon
            className="w-7 cursor-pointer ml-5"
            onClick={() => onSubmitAdd(dataDefaultToAdd)}
          />
        </div>
      ),
      measure: (
        <div className="flex items-center gap-x-3">
          {translateMeasure(productFromStock?.measure)}
          <ProductTypeBadge type={productFromStock.type} />
        </div>
      ),
      stock: (
        <div className="flex justify-start gap-x-3">
          <span className="text-start">{stock} Disponible</span>
          <span className="flex justify-start">
            {formatCurrency(
              truncateValue(priceDefault.price, 2),
              priceDefault.codeCurrency
            )}
          </span>
        </div>
      ),
      input:
        productSelected === productFromStock?.id ? (
          <div>
            <Input
              placeholder="Cantidad"
              name="quantityToBuy"
              type={productFromStock.saleByWeight ? "number" : "text"}
              control={control}
              rules={{
                required: "Este campo es requerido",
              }}
              autoFocus
              textAsNumber={!productFromStock.saleByWeight}
            />
          </div>
        ) : (
          ""
        ),
      price:
        productSelected === productFromStock?.id &&
          productFromStock?.prices?.length > 0 ? (
          <div className="w-full">
            {watch!("priceToSell") !== "manual" ? (
              <Select
                name="priceToSell"
                data={productPricesSelect}
                rules={{ required: "Este campo es requerido" }}
                control={control}
                defaultValue={productPricesSelect[0].id}
              />
            ) : (
              <CurrencyAmountInput
                name="priceToSellManual"
                control={control}
                currencies={currenciesSelector}
              />
            )}
          </div>
        ) : (
          ""
        ),
    });
  };

  saleProductsSearch.length > 0 &&
    saleProductsSearch!.map((productFromStock) => {
      pushingData(productFromStock);
    });

  const { salesCategories } = useAppSelector((state) => state.nomenclator);
  let categoriesDisplay: SelectInterface[] = [];
  salesCategories.map((item) => {
    categoriesDisplay.push({ id: item.id, name: item.name });
  });
  // ------------------------

  useEffect(() => {
    const handleEnter = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        onSubmitAdd(); // Mover la lógica del submit fuera si es necesario
      }
    };
    document.addEventListener("keydown", handleEnter);
    return () => document.removeEventListener("keydown", handleEnter);
  }, []);

  return (
    <div className="min-h-[24rem] scrollbar-none">
      <ScrollTypeFilter
        className="my-0"
        title="Categorías de ventas"
        items={categoriesDisplay}
        current={Number(filter?.salesCategoryId) ?? null}
        onChange={(item: string | number | null) =>
          setFilter({
            ...filter,
            salesCategoryId: item,
          })
        }
        scrollbarDisabled={true}
        allButtonDisabled
        allButtonEnd
      />
      <SearchComponent
        findAction={(e: any) => setFilter({ search: e })}
        placeholder={
          !stockAreaId && !areaStockId
            ? "Seleccione un punto de venta para continuar"
            : "Buscar producto"
        }
        disabled={!stockAreaId && !areaStockId}
      />
      <section>
        <div className="mt-5 pr-2 h-[45vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-100">
          {isLoading ? (
            <div className="grid w-full  place-items-center">
              <SpinnerLoading text="Buscando producto" />
            </div>
          ) : productFromStockList?.length === 0 && !search ? (
            <div className="grid w-full h-full place-items-center">
              <EmptyList
                title="Buscar Producto"
                subTitle="Inserte un criterio de búsqueda"
              />
            </div>
          ) : productFromStockList?.length === 0 && search ? (
            <div className="grid w-full h-full place-items-center">
              <EmptyList
                title="Producto no encontrado"
                subTitle="Inserte otro criterio de búsqueda"
              />
            </div>
          ) : (
            <CustomRadioV2
              data={productFromStockList}
              name="supplyProductId"
              control={control}
              action={() => {
                setValue!("quantityToBuy", null);
                clearErrors();
              }}
              className="w-[90%] h-full px-2"
            />
          )}
        </div>

      </section>
    </div>
  );
};
