import { useState } from "react";
import query from "./APIServices";
import useServer from "./useServerMain";
import {
  GeneralAreaIncome,
  MovementInventoryReportInterface,
  ProcessedProductsDataInterface,
} from "../interfaces/ServerInterfaces";
import { generateUrlParams } from "../utils/helpers";

const useServerReports = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  // @ts-ignore
  const [financialReport, setFinancialReport] =
    useState<GeneralAreaIncome | null>(null);

  const [processedProductsData, setProcessedProductsData] = useState<
    ProcessedProductsDataInterface[] | null
  >(null);

  const [costAsset, setCostAsset] = useState<any[] | null>(null);

  const [MovementInventoryReport, setMovementInventoryReport] = useState<
    MovementInventoryReportInterface[] | null
  >(null);

  const { manageErrors } = useServer();

  const getFinancialReport = async (
    filter?: Record<string, string | number | boolean | null>
  ) => {
    setIsFetching(true);

    await query
      .post(`/report/financial/general`, filter!)
      .then((resp) => {
        //@ts-ignore
        setFinancialReport(resp.data);
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const getSummaryOfProcessedProducts = async (
    data: Record<string, string | number | boolean | null>,
    callback: Function
  ) => {
    setIsFetching(true);

    await query
      .post(`/report/production/processed`, data)
      .then((resp) => {
        setProcessedProductsData(resp.data);
        callback();
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const getCostAsset = async (
    data: Record<string, string | number | boolean | null>,
    callback: Function
  ) => {
    setIsFetching(true);

    await query
      .post(`/report/assets/cost`, data)
      .then((resp) => {
        setCostAsset(resp.data);
        callback();
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const getStockInventoryFromEcoCycles = async (
    data: Record<string, string | number | boolean | null>
  ) => {
    setIsFetching(true);

    await query
      .get(`/report/stock/ecocycle/period-inventory${generateUrlParams(data)}`)
      .then((resp) => {
        setMovementInventoryReport(resp.data);
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  return {
    isFetching,
    financialReport,
    getFinancialReport,
    processedProductsData,
    getSummaryOfProcessedProducts,
    getStockInventoryFromEcoCycles,
    MovementInventoryReport,
    costAsset,
    getCostAsset,
  };
};

export default useServerReports;
