import { BusinessInterface, ProductInterface } from '../interfaces/ServerInterfaces'
import DocumentPage from "../components/pdf/DocumentPage";

import {
    View, Text, Image, StyleSheet

} from "@react-pdf/renderer";
import APIMediaServer from '../api/APIMediaServer';
import { blobToDataURL } from './helpers/commons';

interface CookBookPdfInterface {
    products: ProductInterface[];
    businessName: string;
    business: BusinessInterface | null;
}

//Styles
const tableStyles = StyleSheet.create({
    businessLogo: {
        height: 85.4,
        objectFit: "contain",
        paddingLeft: 0.9,
        borderRadius: "50%",
        marginTop: 10,
    },
    tableTitle: {
        marginTop: 10,
        fontSize: 13,
        fontWeight: "semibold",
        textDecoration: "underline",
    },
    table: {
        marginTop: 5,
        paddingBottom: 10,
        gap: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    productCard: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "solid",
        borderBottomColor: "#7a7a7a",
        borderBottomWidth: 1,
    },
    rowHeader: {
        // borderBottom: "solid",
        // borderBottomColor: "#9ca3af",
        // borderBottomWidth: 2,
        backgroundColor: "#000000",
        color: "#ffffff",
        fontWeight: 500
    },
    normalText: {
        fontSize: 12,
        fontWeight: 500,
    },
    bodyText: {
        fontSize: 6,
    },

    productImage: {
        height: 150,
        width: 150,
        objectFit: "contain",
        paddingLeft: 1.8,
        borderRadius: "20%",
    },
});

const CookBookPdf = ({
    products,
    businessName,
    business
}: CookBookPdfInterface) => {


    const getImage = async (logoId: number) => {
        try {
            const response = await APIMediaServer.get("/files/" + logoId, {
                responseType: "blob",
            });
            return await blobToDataURL(response.data);
        } catch (error) {
            // console.error(er ror);
            return require("../assets/image-default.jpg");
        }
    };


    const groupedNames = products.reduce<{ [key: string]: ProductInterface[] }>((acc, item) => {
        const firstLetter = item.name[0].toUpperCase();
        if (!acc[firstLetter]) {
            acc[firstLetter] = [];
        }
        acc[firstLetter].push(item);
        return acc;
    }, {});

    return (
        <DocumentPage>
            <View style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", marginTop: 200, textAlign: 'center', fontSize: 20, fontWeight: 700, gap: 30 }} wrap={false} >

                <Image
                    style={tableStyles.businessLogo}
                    //@ts-ignore
                    src={
                        business?.logo
                            ? getImage(business?.logo?.id!)
                            : require("../assets/image-default.jpg")
                    }
                />

                <Text>{businessName}</Text>
                <Text style={{ fontSize: 30, fontWeight: 800 }}> RECETARIO</Text>
            </View>

            <View style={tableStyles.table} break>
                {
                    Object.keys(groupedNames).map((letter) => (
                        <div key={letter}>
                            <Text style={{ textAlign: 'left' }}>
                                {letter}
                            </Text>
                            {groupedNames[letter].map((prod, idx) => {
                                return (
                                    <View key={idx} style={{
                                        width: "100%",
                                        padding: 2,
                                        marginTop: 10,
                                    }}>

                                        {/* Nombre */}
                                        <Text style={{ textAlign: 'left', paddingBottom: 1, fontSize: 16, fontWeight: 700 }}>
                                            {prod.name}
                                        </Text>

                                        <View
                                            style={
                                                {
                                                    ...tableStyles.productCard,
                                                }
                                            }
                                        >
                                            <div>
                                                {/* Imagen */}
                                                <View style={{ width: "30%" }}>
                                                    <Image
                                                        style={tableStyles.productImage}
                                                        src={
                                                            prod.images.length > 0
                                                                ? getImage(prod.images[0]?.id!)
                                                                : require("../assets/image-default.jpg")
                                                        }
                                                    />
                                                </View>
                                            </div>

                                            <div>
                                                {/* Ingredientes del producto  */}
                                                <View style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start", marginLeft: 25, marginTop: 8 }}>




                                                    {/* Descripción */}
                                                    {
                                                        !!prod.description && (
                                                            < View style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: 400, flexWrap: "nowrap" }}>
                                                                <View>
                                                                    <Text style={{ ...tableStyles.normalText, textAlign: 'left' }}>
                                                                        Descripción:{"          " + (prod.description ?? "")}
                                                                    </Text>
                                                                </View>
                                                            </View>
                                                        )
                                                    }

                                                </View>
                                            </div>

                                        </View>

                                        {/* Nombre */}
                                        <Text style={{ textAlign: 'left', paddingBottom: 2, fontSize: 12, fontWeight: 700 }}>
                                            Pasos de elaboración:
                                        </Text>

                                        {/* Nombre */}
                                        <Text style={{ textAlign: 'left', paddingBottom: 4, fontSize: 12, fontWeight: 500 }}>
                                            {prod.elaborationSteps ?? "-"}
                                        </Text>

                                    </View>
                                )
                            })}
                        </div>
                    ))}
            </View >


        </DocumentPage >
    )
}

export default CookBookPdf
